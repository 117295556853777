import React, { useState } from 'react';

import css from './UnifiedSearch.module.css';
import Sparkle from '../../../icons/Smart';
import { useApolloClient } from '../../../hooks/useApolloClient';
import GET_QUERY_CLASSIFICATION_TYPE from '../../../queries/classifyQuery';
import notify from '../../../lib/notify';
import useNavigation from '../../hooks/useNavigation';
import { ROUTES } from '../../shared/routes';
import AnimatedLogo from '../AxisAnimatedLogo/AnimatedLogo';

const QueryClassificationType = {
  LEXICAL: 'LEXICAL',
  SEMANTIC: 'SEMANTIC',
};

function UnifiedSearch({ onSearch }) {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFocus, setFocus] = useState(false);
  const { query } = useApolloClient();
  const { navigate, params } = useNavigation();

  const navigateByType = (type, question) => {
    if (type === QueryClassificationType.LEXICAL) {
      params.set('search', question);
      navigate(ROUTES.ADV_SEARCH, params.toString());
      return;
    }

    params.set('question', question);
    navigate(ROUTES.AI_SEARCH, params.toString());
  };

  const onSubmit = async (question) => {
    try {
      const { data } = await query({
        query: GET_QUERY_CLASSIFICATION_TYPE,
        variables: { query: question },
      });

      const type = data.classifyQuery.classificationType;
      onSearch?.(question);
      navigateByType(type, question);
    } catch (error) {
      notify.error('We could not identify your question.');
    } finally {
      setIsLoading(false);
    }
  };

  const onKeyDown = (event) => {
    if (event.key !== 'Enter') return;
    if (event.target.value.lenght === 0) return;

    setIsLoading(true);
    onSubmit(event.target.value);
  };

  const showPlaceholder = value.length > 0 || isFocus;
  return (
    <div className={css.main}>
      {!showPlaceholder && (
        <div className={css.placeholder}>
          <Sparkle />
          Ask or search for anything
        </div>
      )}

      <input
        className={css.input}
        type="text"
        autoCorrect="off"
        autoComplete="off"
        value={value}
        disabled={isLoading}
        onKeyDown={onKeyDown}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        onChange={(e) => setValue(e.target.value)}
      />

      {isLoading && <AnimatedLogo customClass={css.logo} />}
    </div>
  );
}

export default UnifiedSearch;
